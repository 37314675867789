$tabletWidth: 1200px
$padWidth: 992px
$phoneWidth: 415px

@mixin tablet
  @media screen and (max-width: $tabletWidth)
    @content
@mixin pad
  @media screen and (max-width: $padWidth)
    @content
@mixin phone
  @media screen and (max-width: $phoneWidth)
    @content

$colorBlue: #3590ff
$colorGrey: #ececec

html
  font-size: 14px
body
  font-size: 1em

.iconfont
  font-size: 1.286em
  &::before
    color: inherit

.trigger
  font-size: 18px
  line-height: 64px
  cursor: pointer
  transition: color 0.3s
  &:hover
    color: #1890ff

#root
  height: 100%
  .ant-layout
    min-height: 100%
a
  color: rgba(0, 0, 0, 0.45)

.textAlign
  &-c
    text-align: center
.mar
  &-2
    margin-bottom: 2%!important
// breadcrumb
.breadcrumb
  list-style: none
  &__item
    display: inline-block
    color: rgba(0, 0, 0, 0.65)
    &--active
      &::after
        display: none
      &::after
        content: "/"
        display: inline-block
        padding: 0 0.3em
        color: rgba(0, 0, 0, 0.45)
      a
        display: inline-block
// header
.header
  background: #fff
  padding: 0 24px
  display: flex
  justify-content: space-between
  nav
    display: inline-block
  &__dropdown
    display: flex
    align-items: center
  .ant-space-item
    display: flex
// content
.content
  margin: 24px 16px 0

// singleContent
.singleContent
  display: -webkit-flex
  display: flex
  -webkit-align-items: center
  align-items: center
  -webkit-justify-content: center
  justify-content: center
  overflow-x: hidden
  &__item
    width: 450px

.cursor
  &-pointer
    cursor: pointer

.flexAlignCenter
  display: flex
  align-items: center

.ant-layout
  background-color: #102E37

.login
  &__title
    font-size: 20px
    color: #eee
  &__button
    width: 100%
  &__item
    display: flex
    justify-content: space-between
    height: 47px
    margin-bottom: 12px
    input, .ant-input-affix-wrapper, .ant-select-selector
      background-color: #0f2a31!important
      border-color: #2d434b!important
      color: #fff
    .ant-select-arrow
      color: #fff
      margin-top: -13px
    &--end
      margin-top: 50px
  &__txt
    text-align: center
    width: 100%
    color: #fff
