@import "./Base.sass"
@import "./Setting.sass"

// 通用的組件，非覆寫ant.design或mixin

// logo
.logo
  &__wrapper
    width: 80%
    padding: 10px
    min-height: 60px
    margin: 16px auto
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
  &__img
    max-width: 100%

// header dropdown button
.header
  &__dropdownButton
    color: rgba(0, 0, 0, 0.45)

// Form InputNumber
.qbForm
  .ant-input-number
    width: 100%
